const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        sign: null,
        isLogin: window.$cookies.get('xintel_user'),
        // isLogin:true,
    }),

    getters: {
        loggedIn(state) {
            return state.isLogin;
        },
    },

    actions: {
        getSignature: async ({ commit }, input) => {
            axios.post('auth/login/wallet', input).then((res) => {
                if (res.sign && res.sign.length > 0) {
                    console.log('Got signature:', res);
                    commit('GET_SIGNATURE_SUCCESS', res);
                }
            });
        },
        LoginAddress: ({ commit }, input) => {
            axios.post('auth/login/wallet', input).then((res) => {
                if (
                    res.data.tokens &&
                    res.data.tokens.access.token.length > 0
                ) {
                    commit('LOGIN_SUCCESS', res.data);
                }
            });
        },
        LoginEmail: async ({ commit }, input) => {
            return axios.post('/auth/login/email', input).then((res) => {
                if (
                    res.data &&
                    res.data.tokens &&
                    res.data.tokens.access.token.length > 0
                ) {
                    commit('LOGIN_SUCCESS', res.data);
                }
            });
        },
        RegisterEmail: async (context, input) => {
            return axios.post('/auth/register-email', input).then((res) => {
                // console.log('register phone', res.data);
                return res;
            });
        },
        Register: ({ commit }, input) => {
            axios.post('auth/register', input).then((res) => {
                commit('REGISTER_SUCCESS', res.data);
            });
        },
        forgotPassword: (context, input) => {
            return axios.post('/auth/forgot-password', input).then((res) => {
                console.log(res.data);
                return res.data;
            });
        },
        verifyEmail: async (context, input) => {
            return axios.post('/auth/active-email', input).then((res) => {
                return res;
            });
        },
        CheckRegister: ({ commit }, input) => {
            axios.post('auth/market/register/system', input).then((res) => {
                commit('REGISTER_SYSTEM_SUCCESS', res.data);
            });
        },
        req_verificationOTP: (context, input) => {
            axios.post('/auth/send-verification-email', input);
        },
        updatePassword: async (context, input) => {
            return axios.post('/auth/change-password', input).then((res) => {
                return res;
            });
        },
        updateSponsor: async (context, input) => {
            return axios.post('/auth/update-sponsor', input).then((res) => {
                return res;
            });
        },
        async updateWallet({ commit }, input) {
            return axios.post('/auth/update-wallet', input).then((res) => {
                if (res.data && res.data.sign && res.data.sign.length > 0) {
                    commit('GET_SIGNATURE_SUCCESS', res.data);
                }
            });
        },
        async registerWallet({ commit }, input) {
            return axios.post('auth/register-wallet', input).then((res) => {
                if (res.data && res.data.sign && res.data.sign.length > 0) {
                    commit('GET_SIGNATURE_SUCCESS', res.data);
                }
            });
        },
        async registerPhone(context, input) {
            return axios.post('/auth/register-phone', input).then((res) => {
                console.log('register phone', res.data);
                return res.data;
            });
        },
        async activePhone(context, input) {
            return axios.post('/auth/active-phone', input).then((res) => {
                // console.log('Active phone', res.data);
                return res;
            });
        },
        async loginPhone({ commit }, input) {
            return axios.post('/auth/login/phone', input).then((res) => {
                if (
                    res.data &&
                    res.data.tokens &&
                    res.data.tokens.access.token.length > 0
                ) {
                    commit('LOGIN_SUCCESS', res.data);
                }
            });
        },
        async sendOTPPhone(context, input) {
            return axios
                .post('/auth/send-verification-phone', input)
                .then((res) => {
                    console.log('Sen OTP Phone: ', res);
                    return res;
                });
        },
        async updateEmail(context, input) {
            return axios.post('/user/update-email', input).then((res) => {
                console.log('update email', res.data);
                return res.data;
            });
        },
        async updatePhone(context, input) {
            return axios.post('/user/update-phone', input).then((res) => {
                console.log('update phone', res.data);
                return res.data;
            });
        },
    },
    mutations: {
        LOGOUT_SUCCESS(state) {
            window.$cookies.remove('xintel_user');
            // window.location.replace('/login');
            state.isLogin = false;
            // router.push({name: 'Login'})
        },
        LOGIN_SUCCESS: (state, data) => {
            state.isLogin = true;
            window.$cookies.remove('xintel_user');
            const { access } = data.tokens;
            console.log(access.token);
            axios.defaults.headers.common.Authorization = `Bearer ${access.token}`;
            window.$cookies.set('xintel_user', { key: access.token }, '8H');
        },
        REGISTER_SUCCESS(state, data) {
            console.log(data);
        },
        REGISTER_SYSTEM_SUCCESS(state, data) {
            console.log(data);
        },
        GET_SIGNATURE_SUCCESS: (state, data) => {
            state.sign = data.sign;
        },
    },
};
