<template>
    <div v-if="Show" class="banner" id="notice-tick">
        <div
            :style="{ '--moveIt': isOverflow }"
            :class="{ isOverflow: isOverflow }"
        >
            <template v-for="(rule, index) in RuleSet">
                <div v-if="rule.show" :key="index" @click="rule.action">
                    <div class="item-banner">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.2em"
                            height="1.2em"
                            viewBox="0 0 256 256"
                            class="warning"
                            v-b-tooltip.hover
                            :title="$t('manaTooLow')"
                        >
                            <path
                                fill="currentColor"
                                d="M240.26 186.1L152.81 34.23a28.74 28.74 0 0 0-49.62 0L15.74 186.1a27.45 27.45 0 0 0 0 27.71A28.31 28.31 0 0 0 40.55 228h174.9a28.31 28.31 0 0 0 24.79-14.19a27.45 27.45 0 0 0 .02-27.71m-20.8 15.7a4.46 4.46 0 0 1-4 2.2H40.55a4.46 4.46 0 0 1-4-2.2a3.56 3.56 0 0 1 0-3.73L124 46.2a4.77 4.77 0 0 1 8 0l87.44 151.87a3.56 3.56 0 0 1 .02 3.73M116 136v-32a12 12 0 0 1 24 0v32a12 12 0 0 1-24 0m28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16"
                            />
                        </svg>
                        {{ rule.title }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StringBanner',
    data() {
        return {
            width: 0,
            scrollWidth: 0,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        isOverflow() {
            if (this.width < this.scrollWidth) {
                return `translateX(-${this.scrollWidth - this.width}px)`;
            }
            return false;
        },

        RuleSet() {
            return [
                {
                    title: 'Update your sponsor now to get the most out of our website',
                    action: () => {
                        this.$bvModal.show('update-sponsor');
                    },
                    show:
                        this.UserInfo &&
                        this.UserInfo.sponsors &&
                        this.UserInfo.sponsors.length === 1,
                },
                {
                    title: this.$t('manaTooLowToMine'),
                    action: () => {
                        this.$router.push({ name: 'XnodeInventory' });
                    },
                    show: this.UserInfo.mana < 10,
                },
                // {
                //     title: 'Update phone number',
                //     action: () => {
                //         this.$bvModal.show('update-phone');
                //     },
                //     show:
                //         !this.UserInfo.phone ||
                //         this.UserInfo.phone === '' ||
                //         this.UserInfo.phone === null,
                // },
                {
                    title: 'Update Email',
                    action: () => {
                        this.openEmailModal();
                    },
                    show:
                        !this.UserInfo.email ||
                        this.UserInfo.email === '' ||
                        this.UserInfo.email === null,
                },
            ];
        },
        Show() {
            return this.RuleSet.find((el) => {
                return el.show;
            });
        },
    },
    methods: {
        handleResize() {
            const el = document.getElementById('notice-tick');
            if (el) {
                this.width = el.offsetWidth;
                this.scrollWidth = el.scrollWidth;
            }
        },

        openEmailModal() {
            this.$bvModal.show('update-email');
            console.log('TEst ');
        },
    },
    watch: {
        RuleSet() {
            this.$nextTick(() => {
                this.handleResize();
            });
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style lang="scss" scoped>
.banner {
    background-color: #fecaca;
    color: #431407;
    font-weight: 600;
    text-align: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    > div {
        display: inline-block;
        white-space: nowrap;
        padding-right: 40px;
        right: 0px;
        > div {
            display: inline-block;
            padding: 5px 15px 0 15px;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                text-decoration: underline;
                color: #451a03;
            }
        }
    }

    .item-banner {
        display: flex;
        align-items: center;
        gap: 5px;
        svg {
            color: #d62d2d;
        }
    }
}

.isOverflow {
    animation: scroll 10s linear infinite;
}
@keyframes scroll {
    20% {
        transform: translateX(0);
    }
    100% {
        transform: var(--moveIt);
    }
}
</style>
